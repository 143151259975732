@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .link {
        @apply cursor-pointer duration-300  hover:text-blue-500 active:text-green-500
    }
    .draw {
        @apply flex items-center justify-center font-bold md:p-5 p-3 h-0 w-0 md:h-6 md:w-6
    }
}