:root {
    scroll-behavior: smooth;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}


/* Handle */
::-webkit-scrollbar-thumb {
    background: #0F2B5B;
    border-radius: 10px;
    height: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #001231;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    text-decoration: none;
    font-weight: 500;
    font-family: Helvetica, sans-serif;
}
.company{
    font-style: italic;
}
.circle {
    border-radius: 50%;
    border: 1px solid transparent;
}
.black{
    background-color: black;
}
.grey{
    color: rgba(128, 128, 128, 0.889);
    font-size: 14px;
}

.connect{
    border-top: 1px solid rgba(128, 128, 128, 0.666);
}
.overlay {
    background-color: rgba(0, 0, 0, 0.444);
}
.shadow{
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}
.choose{
    background: url('../logo/service2.jpg') #225574;
    background-repeat:no-repeat;
    background-size: cover;
    background-position:center;
    background-blend-mode: multiply;
}
.b{
    color: #0000ffc6
}

.bg-image {
    background-image: url("https://images.unsplash.com/photo-1593424469977-77a35fed63c6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.chat {
    background-color: #3B82F6 !important;
    color: white;
}
.chat:hover{
    background-color: #0346b1 !important;
}
.chat:active{
    background-color: #011c47 !important;
}